import "./index.css";
import rimssuper from "../../../assests/images/rims-super.png";
import rimsmanager from "../../../assests/images/rims-manager.png";
import appstore from "../../../assests/images/app-store.png";
import googleplay from "../../../assests/images/google-play.png";
import rimsdasboard from "../../../assests/images/rims-dasboard.png";
import ceoimg from "../../../assests/images/ceo-pic.png";
import { NavLink } from "react-router-dom";

const HeaderSectionPage = () => {
  return (
    <>
      <div class="bg-img">
        <div class="bn1">
          <div class="container content-value">
            <img src={rimsdasboard} class="main-imgrims" />
            <div class="text-banner">
              <img src={ceoimg} class="ceo-img" />
              <div class="banner-name">
                <h1>
                RiMS App:
                  <span>
                    {" "}
                    The ultimate solution for your verification
                    business. Twenty-five years of expertise in verification
                    services blended with the latest technology
                  </span>{" "}
                </h1>
                <div>
                  <h6> CA.K.Rijas FCA</h6>
                  <p>Chief Executive Officer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="main-section" id="box-second">
        <div class="container">
          <div class="boxes-d">
            <div class="one-b box-d">
              <div>
                <img src={rimssuper} class="img-rims" />
              </div>
              <div>
                <h3>
                  RiMS <span>( Supervisor+ Verifier+ Sampler )</span>
                </h3>
                <div class="img-app">
                  <NavLink
                    to="https://apps.apple.com/us/app/sampler-verifier-rims/id1672991239"
                    target="_blank"
                  >
                    <img src={appstore} class="img-btn" />
                  </NavLink>
                  <NavLink
                    to="https://play.google.com/store/apps/details?id=accruon.rims.prime.rims_prime"
                    target="_blank"
                  >
                    {" "}
                    <img src={googleplay} class="img-btn" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div class="two-b box-d">
              <div>
                <img src={rimsmanager} class="img-rims" />
              </div>
              <div>
                <h3>
                  RiMS <span>( Manager )</span>
                </h3>
                <div class="img-app">
                  <NavLink
                    to="https://apps.apple.com/us/app/manager-rims/id1669407400"
                    target="_blank"
                  >
                    {" "}
                    <img src={appstore} class="img-btn" />
                  </NavLink>
                  <NavLink
                    to="https://play.google.com/store/apps/details?id=com.rims.rims_managerapp"
                    target="_blank"
                  >
                    {" "}
                    <img src={googleplay} class="img-btn" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HeaderSectionPage;
